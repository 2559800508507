<template>
  <div class="text-center">
    
    <v-progress-circular
      size="50"
      :color="color"
      indeterminate
      class="mb-3"
    />

    <h4>Cargando...</h4>

  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
}
</script>