<template>
  <div>
    <v-card class="newsCard" elevation="5" @click="goToArticle()">
      <v-img
          :src="imgSrc"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          :height="(size == 'lg') ? '450px' : '190px'"
      >
        <p class="newsTitle" :class="(size == 'lg') ? 'text-h4' : (size == 'md' ? 'text-h6' : 'text-md-body-1')">{{title}}</p>
      </v-img>

      <v-card-actions class="py-0">

        <v-icon class="mr-2">mdi-calendar</v-icon>
        <p class="pt-4 pb-0">{{formattedDate}}</p>

      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {getLongMonthName} from "@/libs/dateHelpers";

export default {
  name: 'NewsCard',
  computed: {
    imgSrc() {
      return this.imgURL ? `${process.env.VUE_APP_APIURL}/uploads/${this.imgURL}` : 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'
    },
    formattedDate: function() {
      return `${this.date.getDate()} de ${getLongMonthName(this.date)}`
    }
  },
  props: {
    size: {
      type: String,
      default: 'md'
    },
    imgURL: {
      type: String,
    },
    title: {
      type: String,
      default: 'Nombre noticia'
    },
    date: {
      type: Date,
      required: true,
    },
    slug: {
      type: String,
      default: null
    }
  },
  methods: {
    goToArticle() {
      if(this.slug) {
        this.$router.push(`/noticias/${this.slug}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.newsCard {
  transition: all .15s;
  background-color: white;

  & .v-image {
    transition: all .15s;
  }

  &:hover {
    transform: scale(1.015);
    cursor: pointer;

    & .v-image {
      filter: opacity(90%);
    }
  }
}

.newsTitle {
  padding: 10px;
  max-width: 75% !important;
  margin-bottom: 3px;
}

</style>
