<template>
  <v-footer padless dark color="ucnbackground">

    <v-container>

      <v-row class="pt-6 mb-2">
        <v-col cols="12" md="5">
          <img src="@/assets/logoucn.webp" alt="Logo UCN" width="300">
        </v-col>
        <v-col cols="12" md="7">
          <h3 class="mt-3">Galpón Cultural UCN</h3>
          <h4 class="mb-2">Área Artístico Cultural del Departamento Estudiantil - UCN Coquimbo</h4>
          <p class="text-caption mb-0">{{contact.phone}}</p>
          <p class="text-caption mb-0">{{contact.email[0]}}</p>
          <p class="text-caption">{{contact.address}}</p>
        </v-col>
      </v-row>

      <v-row class="pt-2 pb-8">

        <!-- Logo and social networks -->
        <v-col lg="4" md="6" sm="12" xs="12">

          <h4>Redes sociales Galpón Cultural UCN</h4>

          <v-divider class="my-3"></v-divider>

          <v-btn v-if="socialNetworks.facebook.isEnabled" :href="socialNetworks.facebook.url" color="#3b5998" class="mr-2 mt-2" small>
            <v-icon dark class="mr-2">mdi-facebook</v-icon>
            Facebook
          </v-btn>

          <v-btn v-if="socialNetworks.instagram.isEnabled" :href="socialNetworks.instagram.url" small class="instagramButton mr-2 mt-2">
            <v-icon dark class="mr-2">mdi-instagram</v-icon>
            Instagram
          </v-btn>

          <v-btn v-if="socialNetworks.youtube.isEnabled" :href="socialNetworks.youtube.url" color="#ff0000" small class="mt-2">
            <v-icon dark class="mr-2">mdi-youtube</v-icon>
            Youtube
          </v-btn>

        </v-col>

        <!-- Sitemap -->
        <v-col lg="4" md="6" sm="12" xs="12">
          <h4>Mapa del sitio</h4>

          <v-divider class="my-3"></v-divider>

          <router-link v-for="item in navigation" :key="item.name" :to="item.link" tag="li" class="footerlink">{{item.name}}</router-link>

        </v-col>

        <!-- Campus contact info -->
        <v-col lg="4" sm="12" xs="12">

          <h4>Contacto Universidad Católica del Norte</h4>

          <v-divider class="my-3"></v-divider>

          <v-expansion-panels class="campusexpansion" flat>

            <v-expansion-panel>
              <v-expansion-panel-header>Antofagasta</v-expansion-panel-header>
              <v-expansion-panel-content>Casa Central. Angamos 0610, Antofagasta | +56 55 2355000</v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Coquimbo</v-expansion-panel-header>
              <v-expansion-panel-content>Larrondo 1281, Coquimbo | +56 51 2209891</v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>San Pedro de Atacama</v-expansion-panel-header>
              <v-expansion-panel-content>Calle Gustavo Le Peige 380, San Pedro de Atacama | +56 55 2851002</v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Santiago</v-expansion-panel-header>
              <v-expansion-panel-content>Av. Libertador Bernardo O'Higgins 292, Of.22, Santiago | +56 2 22226216</v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

        </v-col>
      </v-row>
    </v-container>

  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    navigation: Array,
  },
  data () {
    return {
      //data
      contact: {
        name: '',
        email: [],
        phone: '',
        address: '',
      },
      socialNetworks: {
        facebook: {
          url: '',
          isEnabled: false
        },
        instagram: {
          url: '',
          isEnabled: false
        },
        youtube: {
          url: '',
          isEnabled: false
        },
      }
    }
  },
  methods: {
    async loadContactInfo() {
      try {

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/contactinfo`)

        if(response.data.data) {
          this.contact = { ...this.contact, ...response.data.data }
        }

      } catch (e) {
        console.error(e)
      }
    },
    async loadSocialNetworks() {
      try {

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/socialnetworks`)

        if(response.data.data) {
          this.socialNetworks = { ...this.socialNetworks, ...response.data.data }
        }

      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {
    Promise.all([this.loadContactInfo(), this.loadSocialNetworks()])
  }
}
</script>

<style lang="scss" scoped>

.campusexpansion .v-expansion-panel {
  background-color: rgba(0,0,0,0) !important;
  border-bottom: 1px solid rgba(255,255,255,0.1);

  &.v-expansion-panel--active {
    background-color: rgba(0,0,0,0.1) !important;
  }

  & .v-expansion-panel-content {
    font-size: 0.9rem;
  }

}

.footerlink {
  cursor: pointer;
}

.instagramButton {
  background: radial-gradient(circle farthest-corner at 35% 90%, #e2aa48, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

</style>