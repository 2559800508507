<template>
  <div class="secondContainer py-5">
    <v-container>

      <v-row>
        <v-col class="textSection order-last order-md-first" cols="12" md="5">
          <p class="text-title">Te Invitamos a ser parte de nuestras Actividades, Talleres y Eventos</p>

          <p class="text-md-body-1 pl-4">Nuestras Actividades del Área artístico cultural del Departamento Estudiantil de la Universidad Católica del Norte Sede Coquimbo comprenden espacios artísticos para estudiantes UCN y para Todo público. Presentamos Actividades Semanales, Anuales y también de entrada liberada</p>
        </v-col>
        <v-col cols="12" md="7">
          <v-img src="@/assets/bck2section.jpg" class="sideImg"></v-img>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "InvitationSection",
}
</script>

<style lang="scss" scoped>

.secondContainer {
  background-color: #fff;
}

.textSection {
  padding-top: 50px;
  padding-left: 15px;
  backdrop-filter: blur(25px);
  border-right: 12px solid #f65720;
  box-shadow: 10px 0px 5px rgba(0,0,0,.05);
  z-index: 1;

  & .text-title {
    font-size: 2rem;
    line-height: 2rem;
  }
}

.sideImg {
  position: relative;
  border-radius: 12px;
  margin-left: -55px;
}

</style>