<template>
  <div class="secondContainer py-3">
    <v-container>

      <v-row>
        <v-col align="center">
          
          <v-alert
            shaped
            prominent
            text
            dark
            max-width="800px"
            elevation="3"
            class="regAlert"
          >

            <v-img
              alt="Logo UCN"
              class="shrink mb-2"
              contain
              src="@/assets/logoGalpon.png"
              transition="scale-transition"
              width="130"
            />

            <v-row align="center" justify="center">
              <v-col md="8" sm="12">
                <v-alert type="success" dense border="bottom" class="pb-4 mb-4">
                  <h4 class="text-h5">
                    Inscripciones de Talleres Abiertas
                  </h4>
                </v-alert>
              </v-col>
            </v-row>
            <div class="mb-1">Ya puedes ser parte de nuestros talleres semestrales para Estudiantes UCN de forma gratuita!</div>
            <div class="text-body-2">Nuestros talleres son recreativos, formativos y no son evaluados</div>

            <v-divider class="my-6" />

            <v-row align="center" >
              <v-col>
                <v-btn color="info" elevation="4" to="/talleres">
                  Ver todos los talleres
                  <v-icon class="ml-2">mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "RegistrationSection",
}
</script>

<style lang="scss" scoped>
.regAlert {
  background: linear-gradient(to right, #3CA55C, #B5AC49) !important;
}

</style>