import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ucnprimary: '#7e9bc0',
        ucnsecondary: '#ac6d33',
        ucnbackground: '#23415b',
      },
    },
  },
  lang: {
    locales: { es, en},
    current: 'es',
  },
});
