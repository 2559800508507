import axios from 'axios'
import store from './store'

export default function setup(){

  //Set Token
  axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('galpon_token')

    if(token){
      config.headers['Authorization'] = `Bearer ${ token }`
    }
    return config

  },(error) => {
    return Promise.reject(error)
  })

  axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (_resolve, reject) {
        if(!err.status && err == 'Network Error'){
          console.error('Error: Se ha desconectado del Servidor, intente nuevamente')
        }
        if (err.status === 401 || err.config || !err.config.__isRetryRequest) { //Expired Token
          if(err.response.data.error == 'Debe iniciar sesión') {
            console.log('Su Token de Acceso ha expirado, por favor inicie sesión nuevamente')
            store.dispatch('API_LOGOUT')
          }
        }
        return reject(err)
    })
  })

}
