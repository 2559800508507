import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: () => import('../views/News.vue')
  },
  {
    path: '/noticias/:slug',
    name: 'VerNoticia',
    component: () => import('../views/ViewArticle.vue')
  },
  {
    path: '/programacion',
    name: 'Programacion',
    component: () => import('../views/Programming.vue')
  },
  {
    path: '/talleres',
    name: 'Talleres',
    component: () => import('../views/Courses.vue')
  },
  {
    path: '/talleres/:id',
    name: 'Ver Taller',
    component: () => import('../views/ViewCourse.vue')
  },
  {
    path: '/proyectos',
    name: 'Proyectos',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/galeria',
    name: 'Galeria',
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/quienessomos',
    name: 'Quienes Somos',
    component: () => import('../views/WhoWeAre.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/inscripcion',
    name: 'InscripcionTalleres',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/verinscripcion/:id',
    name: 'VerInscripcionTaller',
    component: () => import('../views/ViewPublicCourseRegistration.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/admin/news',
    name: 'AdminNews',
    component: () => import('../views/admin/News.vue')
  },
  {
    path: '/admin/news/createarticle',
    name: 'AdminCreateArticle',
    component: () => import('../views/admin/CreateArticle.vue')
  },
  {
    path: '/admin/news/editarticle/:slug',
    name: 'AdminEditArticle',
    component: () => import('../views/admin/EditArticle.vue')
  },
  {
    path: '/admin/programming',
    name: 'AdminProgramming',
    component: () => import('../views/admin/Programming.vue')
  },
  {
    path: '/admin/professors',
    name: 'AdminProfessors',
    component: () => import('../views/admin/Professors.vue')
  },
  {
    path: '/admin/courses',
    name: 'AdminCourses',
    component: () => import('../views/admin/Courses.vue')
  },
  {
    path: '/admin/registrationstatus',
    name: 'AdminRegistrationStatus',
    component: () => import('../views/admin/RegistrationStatus.vue')
  },
  {
    path: '/admin/viewlastregistrations',
    name: 'AdminViewLastRegistrations',
    component: () => import('../views/admin/ViewRegistrations.vue')
  },
  {
    path: '/admin/registrations',
    name: 'AdminRegistrations',
    component: () => import('../views/admin/Registrations.vue')
  },
  {
    path: '/admin/registrations/create',
    name: 'AdminCreateRegistration',
    component: () => import('../views/admin/CreateRegistration.vue')
  },
  {
    path: '/admin/registrations/edit/:id',
    name: 'AdminEditRegistration',
    component: () => import('../views/admin/EditRegistration.vue')
  },
  {
    path: '/admin/gallery',
    name: 'AdminGallery',
    component: () => import('../views/admin/Gallery.vue')
  },
  {
    path: '/admin/contact',
    name: 'AdminContact',
    component: () => import('../views/admin/Contact.vue')
  },
  {
    path: '/admin/staff',
    name: 'AdminStaff',
    component: () => import('../views/admin/Staff.vue')
  },
  {
    path: '/admin/socialnetworks',
    name: 'AdminSocialNetworks',
    component: () => import('../views/admin/SocialNetworks.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
