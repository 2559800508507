const monthNamesSp = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
]

export function getLongMonthName(date) {
    return monthNamesSp[date.getMonth()];
}

export function getShortMonthName(date) {
    return monthNamesSp[date.getMonth()].substring(0, 3);
}

export function getYYYYMMDD(date) {
    return date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1 ) + "-" + ((date.getDate() < 10) ? `0${date.getDate()}` : date.getDate());
}

export function getDDMMYYYY(date) {
    const YYYYMMDD = getYYYYMMDD(date);
    return YYYYMMDD.substring(8, 10) + "-" + YYYYMMDD.substring(5, 7) + "-" + YYYYMMDD.substring(0, 4);
}

export function getHHMM(date) {
    return ((date.getHours() < 10) ? `0${date.getHours()}` : date.getHours()) + ":" + ((date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes());
}

export function datesAreOnSameDay(first, second) {
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
}