<template>
  <h2 class="pl-2 shadowTitle" :class="[color]">{{title}}</h2>
</template>

<script>
export default {
  name: "ShadowedTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "st-orange"
    }
  },
}
</script>

<style lang="scss" scoped>

.shadowTitle {
  padding: 5px 10px 5px 10px;
  background-color: rgba(0,0,0,0.3);
  border-radius: 2px;
  box-shadow: 5px 5px 3px rgba(0,0,0,0.15);
  backdrop-filter: blur(15px);
  margin-bottom: 5px;
  margin-left: 8px;

  &.st-orange {
    border-left: 10px solid #f16f43;
  }

  &.st-blue {
    border-left: 10px solid rgba(21, 123, 218, 0.75);
  }

}

</style>