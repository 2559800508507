<template>
  <v-card class="mx-auto my-5" max-width="374" elevation="5">
    <v-img height="250" :src="imgSrc">

      <v-card-subtitle v-if="inRegistrationProcess">
        <v-chip
          bottom
          align="center"
          color="green"
          label
          text-color="white"
          small
        >
            <v-icon left> mdi-draw-pen </v-icon>
            Inscripciones abiertas
        </v-chip>
      </v-card-subtitle>

    </v-img>

    <v-card-title class="courseTitle">{{course.name}}</v-card-title>

    <v-card-text>

      <div class="text-subtitle-1 mb-3">
        Profesor/a: {{course.professor.name}}
      </div>

      <div>
        {{course.shortDescription}}
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab>Horarios</v-tab>
      <v-tab>Contacto</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item>
        <v-card-text>

          <p v-if="!course.schedules || course.schedules.length < 1">No hay horarios disponibles para este taller</p>

          <v-chip-group v-else column>
            <v-chip v-for="(sch, idx) in course.schedules" :key="idx">
              {{sch.day}} {{sch.start}} hrs.
            </v-chip>
          </v-chip-group>

        </v-card-text>
      </v-tab-item>

      <v-tab-item>
        <v-card-text>

          <div class="my-2">
            <v-chip class="mr-2">
              <v-icon left>mdi-account</v-icon>
              Profesor/a:
            </v-chip>
            <span>{{course.professor.name}}</span>
          </div>

          <div>
            <v-chip class="mr-2">
              <v-icon left>mdi-mail</v-icon>
              Email:
            </v-chip>
            <span>{{course.professor.email}}</span>
          </div>

        </v-card-text>
      </v-tab-item>

    </v-tabs-items>

    <v-divider style="opacity: 0.3"></v-divider>

    <v-card-actions>
      <v-row>
        <v-col class="text-right">
          <v-btn @click="goToCourse(course._id)" color="blue darken-2" small block text class="py-5">
            <v-icon class="mr-2">mdi-information</v-icon>
            Ver ficha del taller {{(inRegistrationProcess) ? ' / Inscribirse' : ''}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CourseOverview",
  props: {
    course: {
      type: Object,
      required: true
    },
    inRegistrationProcess: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    imgSrc() {
      return this.course.imgURL[0] ? `${process.env.VUE_APP_APIURL}/uploads/${this.course.imgURL[0]}` : 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'
    },
  },
  data: () => ({
    tab: 0
  }),
  methods: {
    goToCourse(courseName) {
      this.$router.push(`/talleres/${courseName}`)
    }
  }
}
</script>

<style scoped>
.courseTitle {
  font-size: 1.4rem;
  padding-bottom: 5px;
  word-break: normal;
  min-height: 80px;
}
</style>