import { render, staticRenderFns } from "./WhoWeAreSection.vue?vue&type=template&id=7e377683&scoped=true&"
import script from "./WhoWeAreSection.vue?vue&type=script&lang=js&"
export * from "./WhoWeAreSection.vue?vue&type=script&lang=js&"
import style0 from "./WhoWeAreSection.vue?vue&type=style&index=0&id=7e377683&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e377683",
  null
  
)

export default component.exports