<template>
  <div class="secondContainer py-3">
    <v-container>

      <v-card class="pa-4" elevation="4">

        <v-row>

          <v-col cols="12" md="6">

            <div class="d-md-none">
              <h2>Acerca de Nosotros</h2>

              <v-row class="mb-3">
                <v-col cols="12">
                  <v-card class="titleUnderline" min-height="12px" elevation="1" />
                </v-col>
              </v-row>
            </div>
            

            <v-img
              alt="Logo UCN"
              class="shrink mb-2 rounded-lg"
              contain
              src="@/assets/galpon-team.jpg"
              transition="scale-transition"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-container class="pa-4">

              <div class="d-none d-md-block">
                <h2>Acerca de Nosotros</h2>

                <v-row class="mb-3">
                  <v-col cols="12">
                    <v-card class="titleUnderline" min-height="12px" elevation="1" />
                  </v-col>
                </v-row>
              </div>

              <v-row class="mb-3">
                <v-col cols="12" align="center">
                  <v-card class="backBlue py-3" dark min-height="12px" elevation="1">
                    <v-img
                      alt="Logo UCN"
                      class="shrink mb-2"
                      contain
                      src="@/assets/logoGalpon.png"
                      transition="scale-transition"
                      width="120"
                    />
                    <h2>Galpón Cultural</h2>
                    <h4 class="text-subtitle-1 px-5">Área Artístico Cultural del Departamento Estudiantil - UCN Coquimbo</h4>
                  </v-card>
                </v-col>
              </v-row>

            </v-container>
          </v-col>
      
        </v-row>

        <v-row class="pt-1">

          <v-col cols="12">
            <v-divider class="mb-4"></v-divider>

            <p class="mb-5">El Galpón Cultural UCN, inaugurado el 4 de Abril de 2004, es un espacio fundado y administrado por el Área Artístico Cultural del Departamento Estudiantil de la Universidad Católica del Norte, fue creado con el propósito de dar un espacio profesional y eficiente a los intereses artístico culturales de los estudiantes UCN, sede Coquimbo.</p>
            <p class="text-body-2">Las actividades del Galpón Cultural UCN, se enfocan principalmente en entregar a los estudiantes talleres formativos en diversas disciplinas del arte y la cultura, permitiendo descubrir y desarrollar talentos y grupos artísticos, como también brindar actividades interdisciplinarias como Cafés Literarios, Muestras Artísticas, Festival de la Canción Universitaria, Encuentros de Teatro, Encuentros de Danza y Ferias Artesanales Universitarias, entre otros eventos programados anualmente, logrando generar espacios de participación tanto para el estudiantado, como para académicos y personal de apoyo UCN, incluyendo a los habitantes de Guayacán, y de la Región de Coquimbo.</p>
          </v-col>

          <v-col cols="12" align="center" class="pt-0">
            <v-btn color="green" dark elevation="4" to="/quienessomos" class="mr-2">
              Quienes Somos
              <v-icon class="ml-2">mdi-account</v-icon>
            </v-btn>
            <v-btn color="info" elevation="4" to="/contacto">
              Contacto
              <v-icon class="ml-2">mdi-card-account-details</v-icon>
            </v-btn>
          </v-col>

        </v-row>

      </v-card>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "RegistrationSection",
}
</script>

<style lang="scss" scoped>

.backBlue {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, #2980b9 25%, #2c3e50 10%);
  border-radius: 7px 7px 7px 7px;
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}

</style>
