import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const apiServerURL = process.env.VUE_APP_APIURL

export default new Vuex.Store({
  state: {
    user: {
      name: localStorage.getItem('galpon_name') || null,
      email: localStorage.getItem('galpon_email') || null,
    },
    apiURL: apiServerURL,
  },
  mutations: {
    AUTH_SUCCESS: (state, userdata) => {
      state.user.name = userdata.name
      state.user.email = userdata.email
    },
    AUTH_LOGOUT: (state) => {
      state.user.name = null
      state.user.email = null
      localStorage.removeItem('galpon_name')
      localStorage.removeItem('galpon_email')
      localStorage.removeItem('galpon_token')
    },
  },
  actions: {
    API_LOGIN(context, loginData) {

      if(!loginData){
        console.log('invalid login data')
        return
      }
      
      //set local storage data
      localStorage.setItem('galpon_name', loginData.name)
      localStorage.setItem('galpon_email', loginData.email)
      localStorage.setItem('galpon_token', loginData.token)

      context.commit('AUTH_SUCCESS', loginData)

    },
    API_LOGOUT(context) {
      context.commit('AUTH_LOGOUT')
      delete axios.defaults.headers.common['Authorization']
    }
  },
})
