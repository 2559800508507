<template>
  <div class="firstContainer pt-5">
    <v-container>

      <!-- Info Banner -->
      <v-row class="px-10 py-0">

        <v-col md="2" sm="6">
          <v-img src="@/assets/logoGalpon.png" max-width="120px" class="mr-3" />
        </v-col>
        <v-col md="6" sm="12" class="titleBck">
          <h1 class="mt-5">Galpón Cultural</h1>
          <h2>Departamento Estudiantil</h2>
          <h2>Área Artístico Cultural - UCN Coquimbo</h2>
        </v-col>

      </v-row>

      <v-row class="mt-3 mb-6">
        <v-col cols="12">
          <v-card class="titleUnderline" min-height="12px" elevation="1" />
        </v-col>
      </v-row>

      <!-- Main News -->
      <v-row>
        <ShadowedTitle title="Últimos eventos y noticias" class="mb-3" />
      </v-row>


      <div v-if="!isLoading">

        <div v-if="news.length > 0">

          <v-row>
            <v-col sm="12" md="7">
              <NewsCard v-if="news.length > 0" size="lg" :title="news[0].title" :imgURL="news[0].imgURL" :date="news[0].date" :slug="news[0].slug"/>
            </v-col>
            <v-col sm="12" md="5">
              <div class="mb-3">
                <NewsCard v-if="news.length > 1" :title="news[1].title" :imgURL="news[1].imgURL" :date="news[1].date" :slug="news[1].slug" />
              </div>

              <NewsCard v-if="news.length > 2" :title="news[2].title" :imgURL="news[2].imgURL" :date="news[2].date" :slug="news[2].slug" />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="3">
              <NewsCard v-if="news.length > 3" size="sm" :title="news[3].title" :imgURL="news[3].imgURL" :date="news[3].date" :slug="news[3].slug" />
            </v-col>
            <v-col md="3">
              <NewsCard v-if="news.length > 4" size="sm" :title="news[4].title" :imgURL="news[4].imgURL" :date="news[4].date" :slug="news[4].slug" />
            </v-col>
            <v-col md="3">
              <NewsCard v-if="news.length > 5" size="sm" :title="news[5].title" :imgURL="news[5].imgURL" :date="news[5].date" :slug="news[5].slug" />
            </v-col>
            <v-col md="3">
              <NewsCard v-if="news.length > 6" size="sm" :title="news[6].title" :imgURL="news[6].imgURL" :date="news[6].date" :slug="news[6].slug" />
            </v-col>
          </v-row>

        </div>

        <div v-else>
          <v-alert type="info" elevation="5" class="my-5" >Lo sentimos, pero no existen noticias por el momento</v-alert>
        </div>

      </div>

      <LoadingIndicator v-else color="white" />

      <v-row>
        <v-col>
          <div class="text-right mt-4">
            <v-btn to="/noticias" color="orange" large class="white--text">
              <v-icon left large dark class="mr-4">mdi-newspaper</v-icon>
              Ver todas las noticias
            </v-btn>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import NewsCard from "@/components/NewsCard";
import ShadowedTitle from "@/components/ShadowedTitle";
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  name: "NewsSection",
  components: {
    NewsCard, ShadowedTitle, LoadingIndicator
  },
  data:() => {
    return {
      //state
      isLoading: true,
      //data
      news: []
    }
  },
  methods: {
    async loadNews() {
      try {

        this.isLoading = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/article/lastones`)

        const news = response.data.data.map(article => {
          return {
            title: article.title,
            imgURL: article.imgURL,
            date: new Date(article.date),
            slug: article.slug,
          }
        })

        this.news = news

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.loadNews()
  },
}
</script>

<style lang="scss" scoped>

.titleBck {

  & h1 {
    font-size: 1.6rem;
  }

  & h2 {
    font-size: 1.2rem;
  }
}

.firstContainer {
  background-color: #fcfcfc;
  background-image: -webkit-linear-gradient(50deg, #efefef 34.9%, rgba(23, 88, 208, 0.85) 35%);
  box-shadow: 0 2px 15px rgba(0,0,0,.25);
  color: white;
  padding-bottom: 35px;
  margin: 0 2% 30px 2%;
  border-radius: 0 0 50px 50px;
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}
</style>