<template>
  <v-app>
    
    <Navbar :navigation="navigation" />

    <v-main class="mainWindow">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <Footer :navigation="navigation" />

  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar, Footer
  },
  data: () => ({
    navigation: [
      {name: 'Inicio', link: '/'},
      {name: 'Noticias', link: '/noticias'},
      {name: 'Programación', link: '/programacion'},
      {name: 'Talleres', link: '/talleres'},
      {name: 'Proyectos', link: '/proyectos'},
      {name: 'Galería', link: '/galeria'},
      {name: 'Quienes Somos', link: '/quienessomos'},
      {name: 'Contacto', link: '/contacto'},
    ]
  }),
};
</script>

<style scoped>
.mainWindow {
  margin-top: 75px;
  padding-top: 16px !important;
}

.searchinput .v-label, .searchinput input {
  font-size: 0.9em !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>