<template>
  <div>

    <!-- Sidebar -->
    <v-navigation-drawer v-model="showSidebar" app temporary>
      <v-list dense nav>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 pb-2"> Galpón Cultural </v-list-item-title>
            <v-list-item-subtitle> UCN Sede Coquimbo </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mb-3"></v-divider>

        <v-list-item v-for="item in navigation" :key="item.name" link :to="item.link">
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  
    <!-- Navbar -->
    <v-app-bar app color="ucnbackground" dark class="navbarucn" >

      <!-- Logo -->
      <div class="d-flex align-center">

        <v-img
          alt="Logo UCN"
          class="shrink mr-2 logoucn"
          contain
          src="@/assets/logoucn.webp"
          transition="scale-transition"
          width="280"
          @click="redirectUCN()"
        />

        <v-img
          alt="Logo Galpón Cultural"
          class="shrink mt-1 logogalpon"
          contain
          src="@/assets/logogalpontipoDpto.png"
          width="280"
        />

      </div>

      <v-spacer></v-spacer>

      <!-- Navbar links -->
      <div class="hidden-sm-and-down">

        <v-btn v-for="item in navigation" :key="item.name" :to="item.link" text small class="mr-1 px-2">
          <span>{{ item.name }}</span>
        </v-btn>

      </div>

      <div class="hidden-md-and-up">
        <v-btn @click="showSidebar = !showSidebar" class="mx-2" text small>
          <v-icon dark>
            mdi-menu
          </v-icon>
        </v-btn>
      </div>

    </v-app-bar>

  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    navigation: Array,
  },
  data: () => ({
    showSidebar: false
  }),
  methods: {
    redirectUCN() {
      window.open('https://www.ucn.cl', '_blank').focus()
    }
  }
}
</script>

<style lang="scss" scoped>

.navbarucn {

  height: 92px !important;
  padding-top: 12px;

  .logoucn {
    position: absolute;
    transition: all .4s;
    cursor: pointer;
  }

  .logogalpon {
    position: absolute;
    transition: all .4s;
    transform: translate(0, 30px);
    opacity: 0;
  }

  &.v-app-bar--is-scrolled .logoucn {
    transform: translate(0, -30px);
    opacity: 0;
  }

  &.v-app-bar--is-scrolled .logogalpon {
    transform: translate(0, 0);
    opacity: 1;
  }

}
</style>
