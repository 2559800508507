<template>
  <div class="mainContainer py-5 mt-5">
    <v-container>

      <v-row class="mt-5">
        <ShadowedTitle title="Talleres del Galpón Cultural" color="st-blue" />
      </v-row>

      <div v-if="!isLoading">

        <v-row v-if="courses.length > 0">
          <v-col v-for="(course, idx) in courses" :key="idx" lg="4" md="6" sm="12">
            <CourseOverview :course="course" :inRegistrationProcess="inRegistrationProcess" />
          </v-col>
        </v-row>

        <div v-else>
          <v-alert type="info" elevation="5" class="my-5" >Lo sentimos, pero no hay cursos disponibles por el momento</v-alert>
        </div>

      </div>

      <LoadingIndicator v-else color="white" />

    </v-container>
  </div>
</template>

<script>
import CourseOverview from "@/components/CourseOverview";
import ShadowedTitle from "@/components/ShadowedTitle";
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  name: "CoursesSection",
  components: {CourseOverview, ShadowedTitle, LoadingIndicator},
  data: () => ({
    //state
    isLoading: true,
    //data
    courses: [],
    inRegistrationProcess: false,
  }),
  methods: {
    async loadCourses() {
      try {

        this.isLoading = true

        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/course`)
        this.courses = (resp.data.data.courses.length > 0) ? resp.data.data.courses : []

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async loadInRegistration() {
      try {
        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/settings`)
        this.inRegistrationProcess = response.data.data.inRegistrationProcess || false

      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    Promise.all([
      this.loadCourses(),
      this.loadInRegistration()
    ])
  }
}
</script>

<style lang="scss" scoped>

.mainContainer {
  background: linear-gradient(135deg, #ea5f50 0%, #f3bd49 100%);
  color: white;
}

</style>