<template>
  <v-container fluid class="mx-0 pa-0 pt-0">

    <NewsSection />

    <RegistrationSection v-if="inRegistrationProcess" />

    <InvitationSection />

    <CoursesSection />

    <WhoWeAreSection />
    
  </v-container>
</template>

<script>
import NewsSection from "@/components/home/NewsSection";
import RegistrationSection from "@/components/home/RegistrationSection";
import InvitationSection from "@/components/home/InvitationSection";
import CoursesSection from "@/components/home/CoursesSection";
import WhoWeAreSection from "@/components/home/WhoWeAreSection";

export default {
  name: 'Home',
  components: {
    NewsSection,
    RegistrationSection,
    InvitationSection,
    CoursesSection,
    WhoWeAreSection,
  },
  data:() => {
    return {
      inRegistrationProcess: false,
    }
  },
  methods: {
    async loadInRegistration() {
      try {
        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/settings`)
        this.inRegistrationProcess = response.data.data.inRegistrationProcess || false

      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.loadInRegistration()
  }
}
</script>
